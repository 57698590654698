"use client"; // Marks this component as a client-side component
import React, { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import { ContentContainer, Layout, Stack } from "./molecules";
import thumbAvtar from "../../public/thumbAvtar.svg";
import { getTestimonialsBySlug } from "../actions/courseNew";
import { useDictionary } from "../context/dictionaryProvider"; // Assuming a client-side context for dictionary
import Image from "next/image";

// Dynamically import the ReviewCarousel component
const ReviewCarousel = dynamic(() => import("./ReviewCarousel"));

// Skeleton loader component
const ClientReviewSkeleton = () => (
  <div className="w-full animate-pulse flex flex-col gap-7 items-center text-center">
    {/* Placeholder for Avatar */}
    <div className="w-24 h-24 bg-gray-300 rounded-full"></div>
    {/* Placeholder for Title */}
    <div className="w-3/4 h-10 bg-gray-300 rounded-md"></div>
    {/* Placeholder for Carousel */}
    <div className="w-full h-64 bg-gray-300 rounded-md"></div>
  </div>
);

const ClientReview = ({ slug, params }) => {
  const [testimonials, setTestimonials] = useState(null);
  const [loading, setLoading] = useState(true);
  const dict = useDictionary();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getTestimonialsBySlug({
          ...params,
          slug,
          limit: 25,
        });
        setTestimonials(response?.data || []);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug, params]);

  if (loading) {
    return (
      <Layout className="clientReview mt-16 lg:mt-32 mb-8">
        <ContentContainer>
          <Stack>
            <ClientReviewSkeleton />
          </Stack>
        </ContentContainer>
      </Layout>
    );
  }

  return (
    <>
      {testimonials?.length > 0 && (
        <Layout className="clientReview mt-16 lg:mt-32 mb-8">
          <ContentContainer>
            <Stack>
              <div className="relative flex flex-col w-full items-center justify-center text-center gap-7">
                <Image
                  src={thumbAvtar.src}
                  alt="thumb"
                  height={110}
                  width={110}
                  loading="lazy"
                />
                <p className="text-[32px] lg:text-5xl font-extrabold leading-normal">
                  {dict?.clientReview?.title || "Client Reviews"}
                </p>
              </div>
              <ReviewCarousel reviews={testimonials} />
            </Stack>
          </ContentContainer>
        </Layout>
      )}
    </>
  );
};

export default ClientReview;
