import dayjs from "dayjs";
import endpoints from "../../lib/endpoints";
import { getRequestNew } from "../../lib/fetchClient";
import { baseUrl } from "../../lib/util";

export const getCourseBySlug = async (params) => {
  try {
    const { slug, lang } = params;
    const url =
      baseUrl + endpoints.singleCourseNew + `&filters[slug][$eq]=${slug}`;

    const courseData = await getRequestNew(url, { lang }, false, false);

    return courseData.data[0];
  } catch (error) {
    console.log(error);
  }
};

export const getCourseMetaBySlug = async (params) => {
  try {
    const { slug, lang } = params;
    const url =
      baseUrl + endpoints.singleCouseMeta + `&filters[slug][$eq]=${slug}`;

    const courseData = await getRequestNew(url, { lang }, false, false);

    return courseData.data?.[0] || null;
  } catch (error) {
    console.log(error);
  }
};
export const getSessionsByName = async (params) => {
  try {
    const { slug, lang } = params;
    const url =
      baseUrl +
      endpoints.sessionsOne +
      `&filters[course][slug][$eq]=${slug}` +
      `&filters[start_date][$gt]=${dayjs().format("YYYY-MM-DD")}`;

    const sessionData = await getRequestNew(url, { lang }, false, true);

    return sessionData;
  } catch (error) {
    console.log(error);
  }
};

export const getTestimonialsBySlug = async (params) => {
  try {
    const { slug, lang } = params;
    let url = baseUrl + endpoints.testimonialsOne;

    if (slug) {
      url += `&filters[course][slug][$eq]=${slug}`;
    }

    const testimonialsData = await getRequestNew(url, { lang }, false, false);

    return testimonialsData;
  } catch (error) {
    console.log(error);
  }
};
