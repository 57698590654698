import React from "react";

const DemandCourseSkeleton = () => {
  return (
    <div className="animate-pulse flex flex-col gap-4 w-[300px]">
      {/* Image Placeholder */}
      <div className="w-full h-[180px] bg-gray-300 rounded-md"></div>

      {/* Category Placeholder */}
      <div className="w-1/4 h-5 bg-gray-300 rounded-full"></div>

      {/* Title Placeholder */}
      <div className="w-3/4 h-5 bg-gray-300 rounded-full"></div>

      {/* Accreditation Placeholder */}
      <div className="flex gap-2 items-center">
        <div className="w-8 h-8 bg-gray-300 rounded-full"></div>
        <div className="w-20 h-4 bg-gray-300 rounded-full"></div>
      </div>

      {/* Description Placeholder */}
      <div className="space-y-2">
        <div className="w-full h-4 bg-gray-300 rounded-md"></div>
        <div className="w-3/4 h-4 bg-gray-300 rounded-md"></div>
      </div>

      {/* Rating Placeholder */}
      <div className="w-1/3 h-4 bg-gray-300 rounded-md"></div>
    </div>
  );
};

export default DemandCourseSkeleton;
